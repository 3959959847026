import { useState, useEffect } from 'react';
import LeftNav from './components/left';
import Main from './components/Main';
import Header from './components/header';
import Footer from './components/Footer';

import './App.css';

const App = () => {

  return (
    <>
      <Header />
      <LeftNav />
      <Main />
      <Footer />
    </>
  );

  /*
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);


  useEffect(() => {
    fetch("http://127.0.0.1:3100/users")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          console.log(result);
          setUsers(result.users);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <LeftNav />

        <ul>
        {users.map(user => (
          <li key={user.id}>
            {user.firstname} {user.lastname}
          </li>
        ))}
        </ul>
      </>
    );
  }

*/
}

export default App;
