import React from 'react'

//import './left.css';

const LeftNav = () => {
  return (
    
    <div className='Left'>
    Домой  
    </div>
  )
}

export default LeftNav