import React from 'react'
import {FaYoutube} from 'react-icons/fa'
import { FiMenu } from 'react-icons/fi'
import {GrSearch} from 'react-icons/gr'


const Header = () => {
  return (
    <div className='Header'>
      <FiMenu size={24} style={ {color: 'white', padding: '1.5rem'} }/>
      <FaYoutube className='YT_icon' color='red' size={32} />
        <a href="" className='YT_button'>ArtTube</a>
        <div id='search_div'>
          <input className='search_input' placeholder='Введите запрос' />
        </div>
        <button id='search_btn'>
          <GrSearch style={{color:'white'}} />
        </button>
        
    </div>
  )
}

export default Header